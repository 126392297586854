import React from 'react'
import CertificazioniCardItem from './CertificazioniCardItem';


const CertificazioniSlider = (props) => {
    const {Icon, title, link, text, certificatoLink, certificatoID} = props.item;
    const liText = text.map((obj, i) => (      
        <li key={i}>{obj}</li>
    ));

  return (        

    <CertificazioniCardItem
        Icon={Icon}
        title={title}
        link={link}
        certificatoLink={certificatoLink}
        certificatoID={certificatoID}
        disc={<>
            <ul>
                {liText}
            </ul>        
        </>
        }
    />    
  )
}

export default CertificazioniSlider
