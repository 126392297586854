import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import Tilt from "react-parallax-tilt";

const CertificazioniCardItem = (props) => {
  const { Icon, disc, title, link, certificatoLink, certificatoID } = props;
  return (
    <Tilt>       
        <Card style={{ marginBottom:20, textAlign:'center' }} className='certificazioni-card-container shadow'>
          <Card.Body>
              <Card.Title>
                <span className='green'><Icon/></span>
                <h1>{title}</h1>
              </Card.Title>
              <Card.Text>
                <p>{disc}</p>
              </Card.Text>              
          </Card.Body>
          <Card.Footer style={{ textAlign:'left', fontSize: '0.5em' }}>                  
              <Row>
              {
              certificatoID !== undefined && certificatoID !== "" ?   
                  <>   
                      <Col xs={6} md={6}>Codice Certificazione: <br /><strong><a className="links" target="_blank" rel="noreferrer" href={certificatoLink}>{certificatoID}</a></strong></Col>
                      <Col xs={6} md={6} style={{textAlign: 'center'}}>
                          <Button
                              href={link}
                              target="_blank" rel="noreferrer"            
                          >
                              <FaCloudDownloadAlt style={{ fontSize: "1.2em" }} />{" "}Download                        
                          </Button>
                      </Col>
                  </>          
                      :
                  <>                    
                      <Col xs={12} md={12} style={{textAlign: 'center'}}>
                          <Button
                              href={link}
                              target="_blank" rel="noreferrer"            
                          >
                              <FaCloudDownloadAlt style={{ fontSize: "1.2em" }} />{" "}Download                       
                          </Button>
                      </Col>
                  </>
              }           
              </Row>
          </Card.Footer>
        </Card>

    </Tilt>
  )
}

export default CertificazioniCardItem;