import React from 'react'
import styled from 'styled-components'
import {IoIosQuote} from "react-icons/io";
import {AiOutlineStar} from "react-icons/ai";
import {Button, Card, Col, Row } from 'react-bootstrap';
import Tilt from "react-parallax-tilt";
import { CiLink } from "react-icons/ci";

const ClientSlider = (props) => {
    const {name, position, img_url, stars, disc, link, textLink} = props.item;
  return (
         
    <Tilt> 
        <Card style={{ margin:20 }} className='card-client-container shadow'>
            <Card.Body>
                <Card.Title>
                    <Header>
                    <span className='quote'><IoIosQuote/></span>
                    <div>
                        {Array(stars).fill().map((_, i) => (
                            <span className='star' key={i}>
                                <AiOutlineStar/>
                            </span>
                        ))}
                    </div>
                </Header>
                </Card.Title>
                <Card.Text>
                    <Body>
                        <Row vertical>
                            <Col xs={12} md={12}>
                                {disc}
                            </Col>
                            <Col xs={12} md={12}>
                            <Button
                                href={link}
                                target="_blank"  
                                style={{marginTop: 20}}          
                            >
                                <CiLink style={{ fontSize: "1.2em" }} />{" "}{textLink}                        
                            </Button>
                            </Col>                            
                        </Row>                    
                    </Body>
                    <Footer>
                        <img src={img_url} alt={name} />
                        <div className="details">
                            <h1>{name}</h1>
                            <p style={{color: "#053c2a"}}>{position}</p>
                        </div>
                    </Footer>
                </Card.Text>              
            </Card.Body>       
        </Card>
    </Tilt>
   

   
  )
}

/*


        <>
        <Container className='card-client-container shadow'>  
            <Header>
                <span className='quote'><IoIosQuote/></span>
                <div>
                    {Array(stars).fill().map((_, i) => (
                        <span className='star' key={i}>
                            <AiOutlineStar/>
                        </span>
                    ))}
                </div>
            </Header>
            <Body>
                {disc}
            </Body>
            <Footer>
                <img src={img_url} alt={name} />
                <div className="details">
                    <h1>{name}</h1>
                    <p>{position}</p>
                </div>
            </Footer>
        </Container>
        </>

*/
export default ClientSlider


const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quote{
        font-size: 3rem;
        color: #01be96;
        opacity: 0.7;
    }

    .star{
        color: #ffcd3c;
        font-size: 1.3rem;
    }
`
const Body = styled.p`
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
`
const Footer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    img{
        width: 4rem;
        height: 4rem;
        border-radius: 50px;
        object-fit: cover;
    }

    h1{
        font-size: 1.2rem;
        font-weight: 700;
        @media(max-width: 580px){
            font-size: 1rem;
        }
        @media(max-width: 538px){
            font-size: 0.9rem;
        }
    }

    p{
        font-size: 0.8rem;
        color: rgba(255,255,255,0.500);
        @media(max-width: 538px){
            font-size: 0.6rem;
        }
    }
`