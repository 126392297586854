import React, { useRef } from 'react'
import progettiData from '../data/progetti.json'
import { Slide } from 'react-awesome-reveal'
import ProgettiCard from '../components/Progetti/ProgettiCard'
import Section from '../components/Section/Section'
import Particle from '../components/Particle'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import ProgettiSlider from '../components/Progetti/ProgettiSlider'



var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows : false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]};

const Projects = (props) => {

  const arrowRef = useRef(null);
  let clientDisc = "";
  clientDisc = progettiData.experience.map((item, i) => (
    <Row>
      <Col xs={12} md={12}>          
        <ProgettiSlider item={item} key={i}/>
      </Col>
    </Row>
  ))

  return (
    <>
    <Particle />  
    
    <Section title="Progetti">
    {props.enableSlider === "false" ? 
      <>

      <Slide direction='bottom' duration={400}>     
        <div className="experience-content">          
            <ul className="experience-list">
              {progettiData.experience.reverse().map((exp) => (
                <li key={`experience-${exp.id}`}>
                
                    <ProgettiCard experience={exp} />
                  
                </li>
              ))}
            </ul>                
        </div>
      </Slide>
      </>
    :
    <>  
    <Container className="clients">
        
        <Row>
            <Col xs={12} md={12}>
                <ProgettiContainer >
                    <Slider ref={arrowRef} {...settings}>
                        {clientDisc}
                    </Slider>
                    <Buttons>
                        <button
                        onClick={() => arrowRef.current.slickPrev()}
                        ><IoIosArrowBack/></button>
                        <button
                        onClick={() => arrowRef.current.slickNext()}
                        ><IoIosArrowForward/></button>
                    </Buttons>
                </ProgettiContainer>
            </Col>
        </Row>

      </Container>
    </>
    }
    </Section>
    </>
  )
}


const ProgettiContainer = styled.div`
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #053c2a;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`

export default Projects