import React from 'react'
import './ProgettiCard.css'
import { Button, Col, Row } from 'react-bootstrap'
import appStoreBlack from '../../assets/app-store-black.png';
import playStoreBlack from '../../assets/Google-Play-Badge.png';

const ProgettiCard = ({ experience }) => {
  let { link, link_google, img, company, title, info, stack } = experience
  return (
    
      <div className="experience-card-wrapper shadow card">
        <div className="experience-card">
          <div className="experience-card-top">
            <div
              className="experience-bg"
              style={{ background: experience.colourPrimary }}
            ></div>
            <h2>{company}</h2>
            <div className="image-wrapper">
              <div
                className="experience-bg logo-bg"
                style={{
                  background: experience.colourSecondary
                    ? experience.colourSecondary
                    : experience.colourPrimary,
                }}
              ></div>
              <img
                className="card-logo"
                src={img}
                alt={`${title}-logo`}
                style={
                  experience.logoheight
                    ? {
                        height: `${experience.logoheight}%`,
                      }
                    : { width: `${experience.logowidth}%` }
                }
              />
            </div>
          </div>
          <div className="experience-card-bottom">
            <div>
              <h2>{title}</h2>
              <br />
              <ul>
                {info.map((point, idx) => (
                  <li key={`${company}-point-${idx}`}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="experience-card-tech">
              <ul>
                {stack.map((tech) => (
                  <li key={`${company}-${tech}`}>{tech}</li>
                ))}
              </ul>
            </div>
            <div className="experience-card-tech">
              <Row>                
               { link && link !=="" ?
                <Col xs={12} md={6} style={{textAlign: 'center'}}>
                  <Button variant="link"
                      href={link}
                      target="_blank"            
                    >
                      <img style={{border: 0, background: ''}} src={appStoreBlack} width='150' height='40' alt={link} />              
                  </Button>
                </Col>
                : null
                }

                { link_google && link_google !=="" ?
                <Col xs={12} md={6} style={{textAlign: 'center'}}>
                  <Button variant="link"
                      href={link_google}
                      target="_blank"            
                    >
                      <img src={playStoreBlack} width='150' height='40' alt={link_google}/>                                   
                  </Button>
                </Col>
                 : null
                }
              </Row>
            </div>
          </div>
        </div>
      </div>
   
  )
}

export default ProgettiCard
