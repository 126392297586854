import React from 'react'
import './Section.css'
import { Fade } from 'react-reveal'

const Section = (props) => {
  return (
    <section style={{paddingBottom: 50, paddingTop: 50}}>
      <Fade left duration={1000} distance="70px">
        <h4 className="section-title">{props.title}</h4>
      </Fade>
      <Fade right duration={1000}>
        <div className="underline"></div>
      </Fade>
      {props.children}
    </section>
  )
}

export default Section
