import React from 'react'
import ProgettiCard from './ProgettiCard'


const ProgettiSlider = (props) => {
   console.log(props.item);
  return (
    <ProgettiCard experience={props.item} /> 
  )
}

export default ProgettiSlider
