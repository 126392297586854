import React from 'react'
import Particle from '../components/Particle';
import About from './About';
import Clients from './Clients';
import Contact from './Contact';
import Services from './Services';
import Certificazioni from './Certificazioni';
import Projects from './Projects';


const Home = () => {
  
  return (
    <>
    <Particle />   

    <About />              
   
    <Services />   

    <Certificazioni enableSlider="true" />   
    
    <Clients />

    <Projects enableSlider="true"  />

    <Contact />

    </>
  );
}


// <Clients /> <Skills theme={themes.dark} />  <CardDev />

export default Home