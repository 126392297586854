import React from "react";
import { MdDeveloperMode } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { Slide } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import Section from "../components/Section/Section";
import ServiceCardItem from "../components/Service/ServiceCardItem";
import { DiAndroid, DiApple, DiDatabase } from "react-icons/di";
import { GrMap } from "react-icons/gr";


const Services = () => {
  return (
    <Container>
      <Section title="Skills" >    
        <Row>
        <Col xs={12} md={4}>
          <Slide direction='left' duration={400}>     
            <ServiceCardItem
              Icon={DiApple}
              title={"Apple Developer"}
              disc={`Progettazione di apps per iPhone e iPad in objective-c e swift. iOS SDK – Objective-C - Cocoa Touch`}
            />
          </Slide>
        </Col>

        <Col xs={12} md={4}>
          <Slide direction='up' duration={400}>        
            <ServiceCardItem
              Icon={DiAndroid}
              title={"Android Developer"}
              disc={`Realizzazione di Apps Android per Smarphone e Tablet in Material Design`}
            />
          </Slide>
        </Col>

        <Col xs={12} md={4}>
          <Slide direction='right' duration={400}>
            <ServiceCardItem
              Icon={CgWebsite}
              title={"Web Designer"}
              disc={`Studio grafico e realizzazione di siti web con diversi framework tra cui React e Angular`}
            />Lorem
          </Slide>
          </Col>
        </Row>

        <Row>
        <Col xs={12} md={4}>
          <Slide direction='left' duration={400}>     
            <ServiceCardItem
              Icon={DiDatabase}
              title={"Database"}
              disc={`PostgresSQL con PostGis, Oracle con Spatial, MySQL, SQLServer, DB2, Access`}
            />
          </Slide>
        </Col>

        <Col xs={12} md={4}>
          <Slide direction='up' duration={400}>        
            <ServiceCardItem
              Icon={GrMap}
              title={"GIS"}
              disc={`Realizzazione Sistemi Informativi Territoriali con GeoServer, MapServer, MapBox ed ESRI. Applicazioni web-gis open-source OpenLayers e Leaflet.`}
            />
          </Slide>
        </Col>

        <Col xs={12} md={4}>
          <Slide direction='right' duration={400}>
            <ServiceCardItem
              Icon={MdDeveloperMode}
              title={"Linguaggi di Sviluppo"}
              disc={`React, Angular, Bootstrap - Java, C#, C++, ASP, PHP, JQUERY, HTML`}
            />
          </Slide>
          </Col>
        </Row>
      </Section>
    </Container>    
  );
};

export default Services;
