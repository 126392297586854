import React from "react";
import Typewriter from "typewriter-effect";

const Type = () => {
    return (
      <Typewriter
      options={{
        strings: [        
          'Software Engineer',
          'Apple iOS & iPad Developer',
          'Android Developer',
          'React & Angular Developer',        
          'MicroServices with Spring Boot & Liberty Developer',
          'Docker Architecture',
          'Analista Programmatore in ambito IT',
          'Progettazione e Realizzazione di software GIS',
          'Sistemi Informativi Territoriali sia Web che Desktop',
          'Progrettazione di Database Spaziali',
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
        cursor: '<',
        delay: 100,
      }}
    />
      )
}

export default Type