import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Section from '../components/Section/Section';
import { Slide } from 'react-awesome-reveal';
import { FiFacebook, FiLinkedin, FiMail, FiPhoneCall, FiTwitter } from 'react-icons/fi';

const Contact = () => {
  return (
    <Container style={{textAlign: "center", alignItems: "center" }} className="links">
      
      <Section title="Contatti" >    
        <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={2}>
              <Slide direction="left" delay={1}>
              <span>
                <FiPhoneCall />
              </span>
              </Slide>
              <Slide direction="left">
                <a href="tel:+393276127456">+39 327 612 7456</a>
              </Slide>
            </Col>
            <Col xs={12} md={2}>
              <Slide direction="up" delay={1}>
                    <span>
                      <FiMail />
                    </span>
                  </Slide>
                  <Slide direction="up" delay={1}>
                    <a href="mailto:d.serio.1985@gmail.com">d.serio.1985@gmail.com</a>
                  </Slide>
            </Col>
            <Col xs={12} md={2}>
            <Slide direction="up" delay={1}>
                    <span>
                      <FiTwitter />
                    </span>
                  </Slide>
                  <Slide direction="up" delay={1}>
                    <a href="https://twitter.com/danieleserio">@danieleserio</a>
                  </Slide>
            </Col>
            <Col xs={12} md={2}>
            <Slide direction="up" delay={1}>
                    <span>
                      <FiLinkedin />
                    </span>
                  </Slide>
                  <Slide direction="up" delay={1}>
                    <a href="https://www.linkedin.com/in/daniele-serio-9a6b3651/">daniele-serio</a>
                  </Slide>
            </Col>          
            <Col xs={12} md={2}>
            <Slide direction="right" delay={1}>
                    <span>
                      <FiFacebook />
                    </span>
                  </Slide>
                  <Slide direction="right" delay={1}>
                    <a href="https://www.facebook.com/daniele.serio85?locale=it_IT">#daniele.serio85</a>
                  </Slide>
            </Col>
            <Col xs={12} md={1}></Col>
        </Row>

      </Section>

      

    </Container>
  )
}

export default Contact