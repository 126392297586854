import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import Particle from '../components/Particle'
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const resumeLink = `docs/CV_Daniele_Serio_2023_ITA.pdf`


const Resume = () => {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    
    setWidth(window.innerWidth);
  }, []);



  return (
    <div>
      <Particle />
      <Container>  
        <Row vertical>
            <Col xs={12} md={12}>
              <Button
              className="mt-4 mb-4"
                variant="primary"
                href={resumeLink}
                target="_blank"
                style={{ maxWidth: "250px" }}
              >
                <AiOutlineDownload />
                &nbsp;Download CV
              </Button>
            </Col>
            <Col xs={12} md={12}>
              <Document file={resumeLink}>
                <Page pageNumber={1} scale={width > 786 ? 2 : 0.6} />
              </Document>
            </Col>
            <Col xs={12} md={12}>
              <Button
              className="mt-4 mb-4"
                variant="primary"
                href={resumeLink}
                target="_blank"
                style={{ maxWidth: "250px" }}
              >
                <AiOutlineDownload />
                &nbsp;Download CV
              </Button>
            </Col>
        </Row>
       
      </Container>
    
    </div>
  )
}

export default Resume