import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Bounce } from 'react-reveal';
import Type from '../components/Home/Type';
import coding from '../assets/coding.svg';
import Tilt from "react-parallax-tilt";
import { Slide } from 'react-awesome-reveal';

//<img src="team-member/daniele_avatar.png" />

const About = () => {
  
  return (
    <>
    
      <Container fluid id="about">
       
          <Row>
            <Col xs={12} md={7} className="header" style={{textAlign: "center", alignItems: "center" }}>                
                <Slide direction='left' duration={400}> 
                    <Row>
                        <Col xs={12} md={12}>
                            <Bounce cascade>                                
                                <h1 className="headig-name">
                                    Ciao, sono <span className="name">Daniele Serio</span>.{' '}
                                    <span className="wave-emoji" role="img" aria-label="waving hand">
                                    👋
                                    </span>
                                </h1>
                            </Bounce>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Bounce cascade>
                                <div style={{paddingBottom: 70,  textAlign: "center", alignItems: "center" }}>
                                    <Type />
                                </div>              
                            </Bounce>  
                        </Col>
                    </Row>                                  
               </Slide>
            </Col>
          
            <Col xs={12} md={5} style={{ paddingBottom: 20,  textAlign: "center", alignItems: "center" }}>          
                <Slide direction='right' duration={400}> 
                    
                        <Tilt>
                            <img
                            src={coding}
                            alt="home pic"
                            className="img-fluid"
                            style={{ maxHeight: "300px" }}
                        />
                        </Tilt>
                   
                </Slide>
            </Col>
          </Row>
        </Container>
    
    </>
  );
}

export default About;