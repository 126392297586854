import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Section from "../components/Section/Section";
import { BsHddNetwork } from "react-icons/bs";
import { GrMap } from "react-icons/gr";
import { SiTypescript } from "react-icons/si";
import { DiApple, DiAndroid, DiAngularSimple, DiBootstrap, DiReact, DiDocker} from "react-icons/di";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import CertificazioniSlider from "../components/Certificazioni/CertificazioniSlider";
import Particle from "../components/Particle";


let clients = [
  {
      Icon : DiApple,
      title : "Apple Developer",
      link: "docs/Attestato%20fine%20corso%20Programmatore%20Apple.pdf",
      text: [
        "Approfondimento xCode",
        "Controllers e Views",
        "Gestione dell'interfaccia Utente",
        "Riconoscimento Gestuale",
        "Storyboard",
        "Giochi e iCloud"
      ]
  },
  {
    Icon : DiAndroid,
    title : "Android Developer",
    link: "docs/Attestato%20fine%20corso%20Programmazione%20Java%20Android.pdf",
    text: [
      "Programmazione Java",
      "Gestione e Utilizzo di Database",
      "Base Dati e SQL",
      "Asset e Layout",
      "Activity e Controllers",
      "Google Maps e Services",
      "Manifest e Google Play"
    ]   
  }, 
  {
    Icon : BsHddNetwork,
    title : "Sist. Reti Cisco CCNA",
    link: "docs/1386774831_Attestato%20fine%20corso%20Sistemista%20Cisco%20.pdf",
    text: [
      "Basi di Rete e Protocolli",
      "Cisco Internetworking OS",
      "Gestione della rete e Routing",
      "IGRP e OSPF",
      "Switching e STP",
      "Virtual LAN, Reti Wireless, WAN",
      "Sicurezza"
    ]
  },
  {
    Icon : GrMap,
    title : "Att. ABACO 3D MAPS",
    link: "docs/1386774831_Attestato%20fine%20corso%20Sistemista%20Cisco%20.pdf",
    text: [
      "Tecniche di Programmazione 3D",
      "Programmazione Java Avanzata",   
    ],   
  },
  {
    Icon : DiAngularSimple,
    title : "Front-End JavaScript Frameworks - Angular",
    link: "docs/Coursera%20Front-End%20JavaScript%20Frameworks%20%20-%20Angular%20R43XGVJMDY2L.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "R43XGVJMDY2L",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/R43XGVJMDY2L"  
  },
  {
    Icon : DiBootstrap,
    title : "Bootstrap 4",
    link: "docs/Coursera%20MN699XRU98ZQ_Bootstrap4_Dicembre_2022_no_scadenza.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "MN699XRU98ZQ",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/MN699XRU98ZQ"  
  },
  {
    Icon : DiReact,
    title : "Front-End JavaScript Frameworks - React",
    link: "docs/Coursera%20R5LYPAT23ZWN_FE_React_Dicembre_2022.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "R5LYPAT23ZWN",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/R5LYPAT23ZWN"  
  },
  {
    Icon : DiReact,
    title : "React - Fundamentals of state management",
    link: "docs/Coursera%20React%20-%20Fundamentals%20of%20state%20management%20NN8EZF4FYCUR.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "NN8EZF4FYCUR",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/NN8EZF4FYCUR"       
  },
  {
    Icon : DiReact,
    title : "React & Express",
    link: "docs/Coursera%20React%20&%20Express%20T6YVUM5YU4ZJ.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "T6YVUM5YU4ZJ",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/T6YVUM5YU4ZJ"       
  },
  {
    Icon : SiTypescript,
    title : "TypeScript in React",
    link: "docs/Coursera%20TypeScript%20in%20React%20LLPT4SLQK7CJ.pdf",
    text: [
      "Corso autorizzato dalla Hong Kong University of Science and Technology e offerto tramite Coursera",   
    ],   
    certificatoID: "LLPT4SLQK7CJ",
    certificatoLink: "https://www.coursera.org/account/accomplishments/certificate/LLPT4SLQK7CJ"       
  },
  {
    Icon : DiDocker,
    title : "Docker",
    link: "docs/Cdocs/DOCKER_UC-6cffd430-80d4-4525-a9af-bf06704653c0.pdf",
    text: [
      "Le fondamenta di Docker",   
    ],   
    certificatoID: "",
    certificatoLink: ""       
  }  
];

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows : false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]};


const Certificazioni = (props ) => {

  const arrowRef = useRef(null);
  let clientDisc = "";
  clientDisc = clients.map((item, i) => (
    <Row>
      <Col xs={12} md={12}>          
        <CertificazioniSlider item={item} key={i}/>
      </Col>
    </Row>
  ))

  return (
    <>
      <Particle />  
      <Container className='clients'>
        <Section title="Attestati & Certificazioni" > 

        {props.enableSlider === "false" ? 
        <>
        <Row>
          {clients.map((item, i) => (            
            <Col xs={12} md={4}>          
              <CertificazioniSlider item={item} key={`progetto-${i}`}/>
            </Col>                          
          ))}
        </Row>            
        </>
        :
        <>  
          <Row>
              <Col xs={12} md={12}>
                  <CertificazioniContainer >
                      <Slider ref={arrowRef} {...settings}>
                          {clientDisc}
                      </Slider>
                      <Buttons>
                          <button
                          onClick={() => arrowRef.current.slickPrev()}
                          ><IoIosArrowBack/></button>
                          <button
                          onClick={() => arrowRef.current.slickNext()}
                          ><IoIosArrowForward/></button>
                      </Buttons>
                  </CertificazioniContainer>
              </Col>
          </Row>
        </>
        }
        </Section>
      </Container>    
    </>
  );
};

const CertificazioniContainer = styled.div`
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #053c2a;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`

export default Certificazioni;
