import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from '../components/Clients/ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Section from "../components/Section/Section";

import { Col, Container, Row } from 'react-bootstrap';

let clients = [
    {
        name : "Marcello Catelli",
        position : "CEO - Objective-Code",
        img_url : "testimonials/img-1.jpg",
        stars : 4,
        disc : `Ancora una volta dimostriamo con i fatti che il nostro corso funziona davvero, perché la programmazione è insegnata in modo diverso e perché solo noi diamo il supporto dopo il corso.`,
        link: 'http://www.iphoneitalia.com/grazie-al-corso-di-objective-code-l-alunno-daniele-serio-guadagna-750-e-al-mese-con-le-sue-app-580891.html',
        textLink: "Articolo su iPhoneItalia"
    },
    
    {
        name : "iPhoneItalia",
        position : "Official Site",
        img_url : "testimonials/img-2.jpg",
        stars : 4,
        disc : `Daniele Serio ha partecipato al Corso iOS Base in Swift di Objective Code ed ha sviluppato e pubblicato su App Store diverse App che gli consentono di portare a casa un guadagno mensile di rispetto...`,
        link: 'http://www.iphoneitalia.com/grazie-al-corso-di-objective-code-l-alunno-daniele-serio-guadagna-750-e-al-mese-con-le-sue-app-580891.html',
        textLink: "Articolo su iPhoneItalia"
    },
    
    {
        name : "iPhoneItalia",
        position : "Official Site",
        img_url : "testimonials/img-2.jpg",
        stars : 4,
        disc : `Daniele Serio ha partecipato al Corso iOS Base in Swift di Objective Code ed ha sviluppato e pubblicato su App Store diverse App che gli consentono di portare a casa un guadagno mensile di rispetto...`,
        link: 'http://www.iphoneitalia.com/grazie-al-corso-di-objective-code-l-alunno-daniele-serio-guadagna-750-e-al-mese-con-le-sue-app-580891.html',
        textLink: "Articolo su iPhoneItalia"
    }
]
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i}/>
    ))
  return (

    <Container className='clients'>
      <Section title="Dicono..." >    
        <Row>
            <Col xs={12} md={12}>
                <Testimonials>
                    <Slider ref={arrowRef} {...settings}>
                        {clientDisc}
                    </Slider>
                    <Buttons>
                        <button
                        onClick={() => arrowRef.current.slickPrev()}
                        ><IoIosArrowBack/></button>
                        <button
                        onClick={() => arrowRef.current.slickNext()}
                        ><IoIosArrowForward/></button>
                    </Buttons>
                </Testimonials>
            </Col>
        </Row>
      </Section>
   
        
    </Container>
  )
}

export default Clients


const Testimonials = styled.div`
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #053c2a;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`