import React from 'react'
import { Card } from 'react-bootstrap';
import Tilt from "react-parallax-tilt";

const CardItem = (props) => {
  const { Icon, disc, title } = props;
  return (
    <Tilt>       
        <Card style={{ marginBottom:20 }} className='card-container shadow'>
          <Card.Body>
              <Card.Title>
                <span className='green'><Icon/></span>
                <h1>{title}</h1>
              </Card.Title>
              <Card.Text>
                <p>{disc}</p>
              </Card.Text>              
          </Card.Body>
        </Card>

    </Tilt>
  )
}

export default CardItem;