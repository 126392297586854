import React from "react";
import {
  Route,
  Routes,
  Navigate
} from "react-router-dom";


import Navbar from '../components/Navbar/Navbar'
import Home from "./Home";

import Project from "./Projects";
import Resume from "./Resume";
import Footer from "../components/Footer/Footer";
import Certificazioni from "./Certificazioni";


function Main() {
  
  return (
    <div>               
    
    <Navbar />
    
        <main id="main">
            <div className="container home-content">
                <div className="row">
                    <div className="col-12 col-lg-auto">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/certificazioni" element={<Certificazioni enableSlider="false"/>} />     
                            <Route path="/project" element={<Project enableSlider="false" />} />
                            <Route path="/resume" element={<Resume />} />       
                            <Route path="*" element={<Navigate to="/"/>} />
                        </Routes>
                    </div>
                </div>
            </div>
        </main>          
    <Footer />

  </div>
    
  );
}

export default Main;
